import React from 'react'
import { Link } from 'react-router-dom'

const Service = () => {
  return (
    <>
        <section className="bg-blue-600 text-white py-16">
            <div className="container mx-auto px-4 text-center">
                <h1 className="text-4xl font-bold mb-4">Our Services</h1>
                <p className="text-lg mb-6">We offer a wide range of services to meet your needs.</p>
                <Link to="/services" className="bg-white text-blue-600 px-6 py-3 rounded-lg font-semibold hover:bg-gray-100 transition duration-300">Explore Services</Link>
            </div>
        </section>
    
        <section id="services" className="py-16">
            <div className="container mx-auto px-4">
                <div className="text-center mb-12">
                    <h2 className="text-3xl font-bold text-gray-800">What We Offer</h2>
                    <p className="text-gray-600 mt-4">Discover the wide variety of services tailored for you.</p>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    
                    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition duration-300">
                        <div className="mb-6 text-blue-600">
                            <svg className="w-12 h-12 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M19.428 15.341A8 8 0 0112 20a8 8 0 01-7.428-4.659m14.856 0A8 8 0 0012 4a8 8 0 00-7.428 4.659M12 12h.01"></path></svg>
                        </div>
                        <h3 className="text-xl font-bold text-gray-800 mb-4">Water Filtration Plant</h3>
                        <p className="text-gray-600">Our mission is to provide quality English language instruction through a variety of courses to international and local students in a professional and supportive atmosphere utilizing our unique English teaching.</p>
                    </div>
                    
                    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition duration-300">
                        <div className="mb-6 text-blue-600">
                            <svg className="w-12 h-12 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 7v10c0 1.105.895 2 2 2h14a2 2 0 002-2V7m-4 0V5a2 2 0 00-2-2H9a2 2 0 00-2 2v2M3 7h18"></path></svg>
                        </div>
                        <h3 className="text-xl font-bold text-gray-800 mb-4">Water Quality</h3>
                        <p className="text-gray-600">Effective asset management in water treatment is a holistic approach that combines technical, financial, and operational considerations to ensure the long-term viability and reliability of water treatment infrastructure.</p>
                    </div>
    
                    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition duration-300">
                        <div className="mb-6 text-blue-600">
                            <svg className="w-12 h-12 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M12 19c4.418 0 8-1.79 8-4V9c0-2.21-3.582-4-8-4S4 6.79 4 9v6c0 2.21 3.582 4 8 4z"></path></svg>
                        </div>
                        <h3 className="text-xl font-bold text-gray-800 mb-4">Water Recycling Assets</h3>
                        <p className="text-gray-600">Our delivery-biased engineers and field technicians are widely deployed throughout the UK, managing inspection, repair & maintenance tasks of over 35,000 water sector assets in a typical year.</p>
                    </div>
    
                </div>
            </div>
        </section>
    
        <section className="bg-blue-600 text-white py-16">
            <div className="container mx-auto px-4 text-center">
                <h2 className="text-3xl font-bold mb-4">Ready to Work with Us?</h2>
                <p className="text-lg mb-6">Contact us today to get started on your next big project!</p>
                <Link to="/contact" className="bg-white text-blue-600 px-6 py-3 rounded-lg font-semibold hover:bg-gray-100 transition duration-300">Contact Us</Link>
            </div>
        </section>
        </>
  )
}

export default Service