import React from 'react'
import BuzzImg from './images/buzz-logo.gif'


const About = () => {
  return (
    <div className="bg-aboutUsBg">
      
        <div className="px-12 sm:px-5 xs:px-3">
          <div className="py-32 px-18 flex justify-center">
            <img src={BuzzImg} alt="logo" className='w-[420px]' />
          </div>
        </div>
        <p className='py-6 px-6'>Facilitech Engineering Enterprises was founded in 2019 by Ali Usman with the vision of transforming the industry.
         Starting as a small venture in Lahore, the company quickly gained a reputation for its innovative
          approach to filterration.Over the years, it expanded its operations globally, introducing 
          groundbreaking products in 2020 and establishing key partnerships 
          with partner companies. Despite facing challenges, 
          Facilitech Engineering Enterprises continued to thrive through adaptation and resilience. Today, it stands as 
          a leader in Engineering Industry, committed to driving future innovation and growth.</p>

    </div>
  )
}

export default About